import React, { useState } from 'react';
import { Outlet, NavLink } from "react-router-dom";

const MobileMenu = ({ isOpen, onClose }) => (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
        <ul>
            <li>
                <NavLink to="/" onClick={onClose} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Forsíða</NavLink>
            </li>
            <li>
                <NavLink to="/um-barborukorinn" onClick={onClose} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Um Barbörukórinn</NavLink>
            </li>
            <li>
                <NavLink to="/barbara-maer" onClick={onClose} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Barbara mær</NavLink>
            </li>
        </ul>

        <button className="close-menu-btn" onClick={onClose}>
        &times;
        </button>
    </div>
  );

const Layout = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
    <header className="header">
        <div className="logo">
        <NavLink to="/"><svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 585 231.3"
  style={{ enableBackground: "new 0 0 585 231.3" }}
  xmlSpace="preserve"
>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n\t.st0{clip-path:url(#SVGID_00000151529737323868638480000010021698773127174532_);fill:#FFEBBE;}\n\t.st1{fill:#FFEBBE;}\n"
    }}
  />
  <g>
    <defs>
      <rect id="SVGID_1_" width={585} height="231.3" />
    </defs>
    <clipPath id="SVGID_00000103252461239735569150000005662017243142242484_">
      <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
    </clipPath>
    <path
      style={{
        clipPath:
          "url(#SVGID_00000103252461239735569150000005662017243142242484_)",
        fill: "#FFEBBE"
      }}
      d="M441,92.5
		c11.4,0,18.7-9,22-15.6c0.5-1,1.1-2.5,2.6-1.9c1.6,0.6,0.4,3.1-0.6,5.1c-1,2-1.6,4.1-1.6,7.2v3.1h20.1V24.1h-20.1v25.5
		c0,15.3-7.4,25.8-15.6,25.8c-7.2,0-10.6-4.1-10.6-19.1V24.1h-20.1v40.2C417.2,83.2,426.7,92.5,441,92.5 M386.1,38.3
		c-1.9-0.4-1.1-2.6-0.1-4.6c1-2,1.8-4.3,1.8-6.5v-3h-20.1v66.3h20.1V64.7c0-16.8,5.9-23.5,24.1-22.2V23.1c-16,0-19.7,4.6-23.6,13.4
		C387.9,37.4,387.3,38.6,386.1,38.3 M325.2,75.4c-9.9,0-15.1-8.3-15.1-18.1c0-9.8,5.2-18.1,15.1-18.1s15.1,8.3,15.1,18.1
		C340.3,67.1,335.1,75.4,325.2,75.4 M325.2,22.1c-21.9,0-35.2,15.8-35.2,35.2s13.3,35.2,35.2,35.2s35.2-15.8,35.2-35.2
		S347.1,22.1,325.2,22.1 M330.2,16.1h17.1V1h-17.1V16.1z M303.1,16.1h17.1V1h-17.1V16.1z M249,75.4c-9.6,0-15.6-8.4-15.6-18.1
		c0-9.7,6-18.1,15.6-18.1c10.1,0,14.6,8.4,14.6,18.1C263.6,67,259.1,75.4,249,75.4 M233.5,0h-20.1v90.5h20.1v-2.4
		c0-1.9-0.5-3.6-1.5-5.4c-1-2.1-2.1-4.3-0.3-4.9c1.3-0.4,1.9,1,2.3,1.8c4.1,7.9,10.9,12.9,20.9,12.9c18.2,0,28.9-15.6,28.9-35.2
		S273,22.1,254.8,22.1c-10.1,0-16.7,5.1-20.9,12.8c-0.5,0.9-1.4,2.6-2.9,1.9c-1.6-0.8,0-3,1-5.1c0.9-1.8,1.4-3.6,1.4-5.5V0z
		 M178.8,38.3c-1.9-0.4-1.1-2.6-0.1-4.6c1-2,1.8-4.3,1.8-6.5v-3h-20.1v66.3h20.1V64.7c0-16.8,5.9-23.5,24.1-22.2V23.1
		c-16,0-19.7,4.6-23.6,13.4C180.6,37.4,179.9,38.6,178.8,38.3 M128.9,58.3c-0.1,13.7-9.4,19.1-15.5,20.1c-5.4,0.9-10.7-1.5-10.7-7
		c0-5.7,4.1-7.8,8.8-8.3C121,61.9,125.7,61.7,128.9,58.3 M82.7,72.7c0,11.1,9.7,19.7,23.1,19.7c11.1,0,18.5-5.9,22.6-14.1
		c0.5-0.9,1-2.4,2.6-1.6c1.4,0.6,0.3,2.6-0.8,4.8c-0.9,1.8-1.4,3.5-1.4,5.5v3.4H149V53.8c0-26.6-15.2-31.7-30.9-31.7
		c-14.8,0-31.2,6.4-33.4,24.1h19.8c1-5,4-9,13.1-9c9.2,0,11.2,4.8,11.3,8.5c0.1,5.7-8.3,4.9-20.1,5.7C94.5,52.3,82.7,57.8,82.7,72.7
		 M42.2,72.9H21.1V53.8h20.6c8.5,0,13.6,3.8,13.6,9.6C55.3,69.5,50.8,72.9,42.2,72.9 M21.1,19.6H40c7.7,0,11.3,2.5,11.3,8
		c0,5.4-4.1,9.1-11.8,9.1H21.1V19.6z M60.1,41.6c6.9-3,12.3-8.4,12.3-16.1C72.4,9.7,60.6,2,41.2,2H0v88.4h48.5
		c18.1,0,27.9-10.8,27.9-25.4c0-8.7-4.8-16.3-15.9-18.7c-1-0.1-2-0.8-2-2.4C58.4,42.8,58.7,42.2,60.1,41.6"
    />
    <path
      style={{
        clipPath:
          "url(#SVGID_00000103252461239735569150000005662017243142242484_)",
        fill: "#FFEBBE"
      }}
      d="M561.2,127.1
		c-11.4,0-18.9,8.9-22,15.6c-0.5,1-1.1,2.5-2.6,1.9c-1.6-0.6-0.4-3.1,0.6-5.1c1-2,1.6-4.1,1.6-7.2v-3.1h-20.1v66.3h20.1V170
		c0-15.3,7.4-25.8,15.6-25.8c7.2,0,10.6,4.1,10.6,19.1v32.2H585v-40.2C585,136.4,575.5,127.1,561.2,127.1 M484.5,127.1
		c-11.4,0-18.9,8.9-22,15.6c-0.5,1-1.1,2.5-2.6,1.9c-1.6-0.6-0.4-3.1,0.6-5.1c1-2,1.6-4.1,1.6-7.2v-3.1h-20.1v66.3h20.1V170
		c0-15.3,7.4-25.8,15.6-25.8c7.2,0,10.6,4.1,10.6,19.1v32.2h20.1v-40.2C508.4,136.4,498.8,127.1,484.5,127.1 M409.6,195.5h20.1
		v-66.3h-20.1V195.5z M409.6,121.1h20.1V105h-20.1V121.1z M375.1,143.3c-1.9-0.4-1.1-2.6-0.1-4.6c1-2,1.8-4.3,1.8-6.5v-3h-20.1v66.3
		h20.1v-25.8c0-16.8,5.9-23.5,24.1-22.2v-19.3c-16,0-19.7,4.6-23.6,13.4C376.8,142.4,376.2,143.6,375.1,143.3 M314.1,180.4
		c-9.9,0-15.1-8.3-15.1-18.1c0-9.8,5.2-18.1,15.1-18.1c9.9,0,15.1,8.3,15.1,18.1C329.2,172.1,324.1,180.4,314.1,180.4 M314.1,127.1
		c-21.9,0-35.2,15.8-35.2,35.2s13.3,35.2,35.2,35.2c21.9,0,35.2-15.8,35.2-35.2S336,127.1,314.1,127.1 M307.1,121.1h12.1l14.1-19.1
		h-16.1L307.1,121.1z M281.7,195.5L252,156.9l27.6-27.8h-24.1l-22.6,24.5c-0.9,1-1.9,1.8-3,0.9c-1.3-0.9-0.5-2.3,0.4-3.3
		c1.9-2.1,3.1-4.4,3.1-9.2v-33.6h-20.1v87h20.1v-20l5.5-5.5l18.6,25.5H281.7z"
    />
  </g>
  <rect y="108.6" className="st1" width="195.5" height="17.8" />
  <rect x="213.2" y="213.6" className="st1" width="371.9" height="17.8" />
</svg>
</NavLink>
        </div>
        <nav>
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
                ☰
            </button>
            <ul className="desktop-menu">
                <li>
                    <NavLink to="/" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Forsíða</NavLink>
                </li>
                <li>
                    <NavLink to="/um-barborukorinn" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Um Barbörukórinn</NavLink>
                </li>
                <li>
                    <NavLink to="/barbara-maer" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : "" }>Barbara mær</NavLink>
                </li>
            </ul>

            <MobileMenu isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
            
        </nav>

        

    </header>

    <div className="main">
    
        <div className="main-content">
            <Outlet />
        </div>

    </div>

    <footer className="footer">
        <div className="footer-social-icons">
            <ul>
            <li className="facebook">
                <a href="https://facebook.com/barborukorinn" target="_blank">
                f
                </a>
            </li>
            {/*<li className="twitter"><a href="#" target="_blank">x</a></li>*/}
            <li className="instagram">
                <a href="https://instagram.com/barborukorinn" target="_blank">
                i
                </a>
            </li>
            <li className="spotify">
                <a
                href="https://open.spotify.com/artist/3UlNRAm2MhWAfg4M1trYNH?si=sM7QkYCwR1iEElpSI-1PLQ"
                target="_blank"
                >
                s
                </a>
            </li>
            <li className="youtube">
                <a
                href="https://www.youtube.com/@barborukorinnihafnarfiri3393"
                target="_blank"
                >
                x
                </a>
            </li>
            </ul>
        </div>        
    </footer>
    </>
  )
};

export default Layout;