import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import BarbaraMaer from './components/BarbaraMaer';

import './App.css';

const App = () => {
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="um-barborukorinn" element={<About />} />
          <Route path="barbara-maer" element={<BarbaraMaer />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;