const Home = () => (
    <>
  <div className="content-text home">
    <p>
      <strong>Barbörukórinn</strong> er kammerkór sem starfar við Hafnarfjarðarkirkju, stofnaður
      vorið 2007.
    </p>
  </div>
  <div className="seperator"></div>
  <div className="content-album">
    <iframe
      src="https://open.spotify.com/embed/album/4fVLQoKhTfPYuPkSwNpSmA?utm_source=generator"
      frameBorder="0"
      allowFullScreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    >
      &lt;/div&gt;
    </iframe>
  </div>
</>

  );
export default Home;