const BarbaraMaer = () => (
    <div className="content-text">
  <h1>Barbara mær (2023)</h1>
  <p>Hljóðritun fór fram í Fella- og Hólakirkju dagana 14. til 17. maí 2022.</p>
  <p>
  <strong>Upptökustjórn</strong> Ragnheiður Jónsdóttir
  <br />
  <strong>Stjórnandi</strong> Guðmundur Sigurðsson
  <br />
  <strong>Hönnun umslags</strong> Jón Ingi Stefánsson
  <br />
  <strong>Teikningar á umslagi</strong> Hlíf Una
  <br />
  <strong>Þakkir fá</strong> Hafnarfjarðarkirkja, Tónlistarsjóður kirkjunnar og
  STEFs, Hljóðritasjóður Rannís, Menningarsjóður FÍH og Söngmálastjóri
  Þjóðkirkjunnar.
</p>
  <strong></strong>
  <h2 className="track-title">1. Faðir vor</h2>
  <p>
    Lag: Auður Guðjohnsen (1975) / Texti: Bæn úr Mattheusarguðspjalli.
    <br />
    <em>
      Music: Auður Guðjohnsen (1975) / Text: A Prayer from the Gospel of St.
      Matthew.
    </em>
  </p>
  <p>
    Faðir vor, þú sem ert á himnum, helgist þitt nafn.
    <br />
    Til komi þitt ríki, verði þinn vilji, svo á jörðu sem á himni.
    <br />
    Gef oss í dag vort daglegt brauð og fyrirgef vorar skuldir.
    <br />
    Svo sem vér og fyrirgefum öðrum.
    <br />
    Eigi leið þú oss í freistni heldur frelsa oss frá illu.
    <br />
    Því þitt er ríkið, þinn er mátturinn, að eilífu.
    <br />
    Amen.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    Our Father, who art in heaven, hallowed be thy name.
    <br />
    Thy kingdom come; thy will be done on earth, as it is in heaven.
    <br />
    Give us this day our daily bread,
    <br />
    And forgive us our trespasses, as we forgive those who trespass against us.
    <br />
    And lead us not into temptation, but deliver us from evil:
    <br />
    For thine is the kingdom, the power, and the glory, for ever and ever.
    <br />
    Amen.
  </p>
  <h2 className="track-title">2. Barbara mær</h2>
  <p>Lag: Hugi Guðmundsson (1977) / Ljóð: Þórarinn Eldjárn (1949)</p>
  <p>
    Barbara mær svo mörgum kær
    <br />
    megum við til þín leita?
    <br />
    Barbara mær svo björt og tær
    <br />
    best er á þig að heita.
  </p>
  <p>
    Verndarðu líf,
    <br />
    veitirðu hlíf,
    <br />
    með okkur muntu æ standa,
    <br />
    með okkur jafnan í anda.
  </p>
  <p>
    Máttirðu þola þraut og kvöl
    <br />
    þegar þú vildir birtu.
    <br />
    Faðir og jarl þér bjuggu böl,
    <br />
    báðir þig lítilsvirtu.
  </p>
  <p>
    Dauða hlaust þú,
    <br />
    deydd fyrir trú
    <br />
    allra sem á þig heita
    <br />
    af þeim sem valdi beita.
  </p>
  <p>
    Mærin með turninn taktu við,
    <br />
    taktu við bænarorðum.
    <br />
    Mænir nú til þín mannkynið,
    <br />
    margt hefur færst úr skorðum.
  </p>
  <p>
    Víða um heim
    <br />
    vertu með þeim,
    <br />
    öllum sem á þig heita,
    <br />
    öllum sem til þín leita.
  </p>
  <p>
    Heimurinn mun því heita á þig
    <br />
    hjálp fá gegn vítiseldi
    <br />
    þegar nú æsa og yggla sig
    <br />
    einræðisherraveldi.
  </p>
  <p>
    Leggðu' okkur lið,
    <br />
    lýstu' okkar svið,
    <br />
    orku frá þér vilja þiggja
    <br />
    þjóðir sem alheiminn byggja.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      Barbara maiden, dear to so many,
      <br />
      May we seek your guidance?
      <br />
      Barbara maiden, so fair and pure,
      <br />
      It is best to invoke you.
    </em>
  </p>
  <p>
    <em>
      You protect life,
      <br />
      Offer protection,
      <br />
      You will forever stand by
      <br />
      us and our spirits.
    </em>
  </p>
  <p>
    <em>
      You endured trial and suffering
      <br />
      When you sought illumination.
      <br />
      Priests and lords caused you evil,
      <br />
      Both disdained you.
    </em>
  </p>
  <p>
    <em>
      You were put to death,
      <br />
      Executed for the sake of the faith
      <br />
      Of all who invoke you,
      <br />
      By those who wield power.
    </em>
  </p>
  <p>
    <em>
      Maiden with your tower receive,
      <br />
      Receive our prayers.
      <br />
      Now humanity is gazing up to you,
      <br />
      Much has been disrupted.
    </em>
  </p>
  <p>
    <em>
      Far and wide,
      <br />
      Be with them,
      <br />
      All who invoke you,
      <br />
      All who seek your guidance.
    </em>
  </p>
  <p>
    <em>
      The world will now invoke you,
      <br />
      Obtain aid against hellfire,
      <br />
      Now with inciting and louring
      <br />
      Czarism and dictatorship,
    </em>
  </p>
  <p>
    <em>
      Be of assistance to us,
      <br />
      Enlighten our realm.
      <br />
      Nations that inhabit the universe
      <br />
      Wish to receive your force of power.
    </em>
  </p>
  <h2 className="track-title">3. Missa Brevis</h2>
  <p>Stefán Arason (1978)</p>
  <p>
    <strong>3. Kyrie</strong>
    <br />
    Kyrie eleison. Christe eleison. Kyrie eleison.
  </p>
  <p>
    <strong>4. Gloria</strong>
    <br />
    Gloria in excelsis Deo, et in terra pax hominibus bonae voluntatis. Laudamus
    te. Adoramus te. Benedicimus te. Glorificamus te. Gratias agimus tibi
    propter magnam gloriam tuam. Domine Deus, rex coelestis, Deus Pater
    omnipotens. Domine Fili unigenite, Jesu Christe. Domine Deus, Agnus Dei,
    Filius Patris: Qui tollis peccata mundi, miserere nobis. Qui tollis peccata
    mundi, suscipe deprecationem nostram. Qui sedes ad dexteram Patris, miserere
    nobis. Quoniam tu solus Sanctus. Tu solus Dóminus. Tu solus Altissimus, Jesu
    Christe. Cum Sancto Spiritu in gloria Dei Patris. Amen.
  </p>
  <p>
    <strong>5. Sanctus</strong>
    <br />
    Sanctus, Sanctus, Sanctus, Dominus Deus Sabaoth. Pleni sunt coeli et terra
    gloria tua. Osanna in excelsis.
  </p>
  <p>
    <strong>6. Agnus Dei</strong>
    <br />
    Agnus Dei, qui tollis peccata mundi, miserere nobis. Lamb of God, who takest
    away the sins of the world, have mercy on us. Agnus Dei, qui tollis peccata
    mundi, miserere nobis.
  </p>
  <p />
  <p>
    <em>
      <strong>Íslensk þýðing</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Drottinn</strong>
      <br />
      Drottinn miskunna þú oss. Kristur miskunna þú oss. Drottinn miskunna þú
      oss.
    </em>
  </p>
  <p>
    <em>
      <strong>Dýrð</strong>
      <br />
      Dýrð sé Guði í upphæðum og friður á jörðu með þeim mönnum, sem hafa góðan
      vilja. Við vegsömum þig. Við blessum þig. Við tilbiðjum þig. Við tignum
      þig. Við þökkum þér vegna mikillar dýrðar þinnar. Drottinn Guð, konungur
      himnanna, Guð Faðir almáttugur Drottinn, Sonurinn eingetni, Jesús Kristur.
      Drottinn Guð, lamb Guðs, Sonur Föðurins. Þú sem ber burt syndir heimsins,
      miskunna þú oss. Þú sem ber burt syndir heimsins, tak við bæn vorri. Þú
      sem situr föðurnum til hægri handar, miskunna þú oss. Því að þú einn ert
      heilagur. Þú einn Drottinn. Þú einn æðstur, Jesús Kristur. Ásamt með
      Heilögum Anda í dýrð Guðs Föður. Amen.
    </em>
  </p>
  <p>
    <em>
      <strong>Heilagur</strong>
      <br />
      Heilagur, heilagur, heilagur ert þú, Drottinn, Guð hersveitanna. Himinn og
      jörð eru full dýrðar þinnar. Hósanna í upphæðum. Blessaður er sá, sem
      kemur í nafni Drottins. Hósíanna í upphæðum.
    </em>
  </p>
  <p>
    <em>
      <strong>Guðs lamb</strong>
      <br />
      Guðs lamb, sem ber burt syndir heimsins, miskunna þú oss. Guðs lamb, sem
      ber burt syndir heimsins, miskunna þú oss. Guðs lamb, sem ber burt syndir
      heimsins, veit þú oss frið.
    </em>
  </p>
  <p>&nbsp;</p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Lord</strong>
      <br />
      Lord, have mercy upon us. Christ, have mercy upon us.
      <br />
      Lord, have mercy upon us.
    </em>
  </p>
  <p>
    <em>
      <strong>Glory</strong>
      <br />
      Glory to God in the highest, and on earth peace, goodwill toward men. We
      praise thee. We adore thee. We bless thee. We glorify thee. We give thanks
      to thee on account of thy great glory. Lord God, king of heaven, God the
      omnipotent Father. Lord the only begotten Son, Jesus Christ. Lord God,
      Lamb of God, Son of the Father: Who takest away the sins of the world,
      have mercy upon us. Who takest away the sins of the world, hear our
      prayer. Who sittest at the right hand of the Father, have mercy upon us.
      For thou alone art holy, thou alone art God, thou alone art most high:
      Jesus Christ, with the Holy Ghost, in the glory of God the Father. Amen.
    </em>
  </p>
  <p>
    <em>
      <strong>Holy</strong>
      <br />
      Holy, Holy, Holy, Lord God of Hosts. Heaven and earth are full of thy
      glory. Hosanna in the highest.
    </em>
  </p>
  <p>
    <em>
      <strong>Lamb of God</strong>
      <br />
      Lamb of God, who takest away the sins of the world, have mercy on us.
      Agnus Dei, qui tollis peccata mundi, dona nobis pacem. Lamb of God, who
      takest away the sins of the world, grant us peace.
    </em>
  </p>
  <h2 className="track-title">7. Ubi caritas et amor</h2>
  <p>
    <strong>Lag: </strong>Auður Guðjohnsen (1975) / <strong>Texti: </strong>
    Latneskur trúartexti/sálmur.
    <br />
    <em>
      <strong>Music:</strong> Auður Guðjohnsen (1975) / <strong>Text:</strong>{" "}
      Latin psalm/religious text.{" "}
    </em>
  </p>
  <p>
    Ubi caritas et amor, Deus ibi est.
    <br />
    Congregávit nos in unum Christi amor.
    <br />
    Timeámus et amémus Deum vivum
    <br />
    et ex corde diligamus nos sincéro.
    <br />
    Ubi caritas et amor, Deus ibi est.
    <br />
    Amen.
  </p>
  <p>
    <em>
      <strong>Íslensk þýðing</strong>
      <br />
      Þar sem kærleikur er og ást, þar er Guð.
      <br />
      Kærleikur Krists sameinar okkur,
      <br />
      í honum erum við eitt.
      <br />
      Óttumst og elskum hinn lifandi Guð
      <br />
      og elskum hvert annað af einlægu hjarta.
      <br />
      Þar sem kærleikur er og ást, þar er Guð.
      <br />
      Amen.
    </em>
  </p>
  <p>
    <em>
      <strong>English translation</strong>
      <br />
      Where charity and love are, God is there.
      <br />
      Christ’s love has gathered us into one.
      <br />
      May we love Him and revere Him, God the living,
      <br />
      And may we love each other with a sincere heart.
      <br />
      Where charity and love are, God is there.
      <br />
      Amen.
    </em>
  </p>
  <h2 className="track-title">8. Hver sem að reisir hæga byggð</h2>
  <p>
    Lag: Bára Grímsdóttir (1960) / Texti: Einar Sigurðsson í Eydölum (1539–1626)
  </p>
  <p>
    Hver sem að reisir hæga byggð
    <br />
    hæsta Guðs skjóli undir
    <br />
    í geymslu Drottins og gleymir hryggð
    <br />
    gefur sig allar stundir.
    <br />
    Sá talar við Guð: Þú tókst við mér,
    <br />
    treysta vil eg því gjarnan þér
    <br />
    og unna á allar lundir.
  </p>
  <p>
    Vel mun og drottinn vernda þig
    <br />
    fyrir vélum allra handa.
    <br />
    Þó skaðleg plágan sýni sig
    <br />
    síst mun þér kunna að granda.
    <br />
    Hans vængjaskjól æ hlífir hér,
    <br />
    í hverri neyð hann sendir þér
    <br />
    sinn skjöld og sannleiks anda.
  </p>
  <p>
    Englum sínum hann setti boð
    <br />
    að sjá til þín og leiða,
    <br />
    á vegum þínum þér veita stoð
    <br />
    og venda öllu til greiða.
    <br />
    Þeir bera þig hægt á höndum sín
    <br />
    og hvergi nái þó fætur þín
    <br />á minnsta steini meiða.
  </p>
  <p>
    Á himni og jörðu heiður og dýrð
    <br />
    sé hæstum föður að vanda,
    <br />
    hans syninum ljúfum líka skýrð
    <br />
    og lofgjörð allra handa
    <br />
    ævinlega sú aldrei dvín,
    <br />
    aukist lofið og virðing þín
    <br />
    með heiðran heilags anda.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Whoever Builds a Tranquil Home</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Translation by Nicholos Jones</strong>
    </em>
  </p>
  <p>
    <em>
      Whoever builds a tranquil home
      <br />
      Under God‘s wing on high
      <br />
      In the Lord‘s store and forgets sorrow,
      <br />
      Devoted every moment,
      <br />
      He speaks to God: Thou tookst me in,
      <br />
      So happy I place my trust in thee
      <br />
      And love with all my being.
    </em>
  </p>
  <p>
    <em>
      The Lord too will protect you well
      <br />
      From treachery at every hand,
      <br />
      Though deadly pestilence shows its face
      <br />
      It has no power to harm you.
      <br />
      His sheltering wing ever saves us here,
      <br />
      In every need he sends to you
      <br />
      His shield and spirit of truth.
    </em>
  </p>
  <p>
    <em>
      His angels he gave his command
      <br />
      To watch over you and lead you on,
      <br />
      On your travels to give support
      <br />
      And smooth your path in all you do.
      <br />
      Easy they carry you in their arms,
      <br />
      And suffer nowhere that your feet
      <br />
      You wound against the smallest stone.
    </em>
  </p>
  <p>
    <em>
      On heaven and earth, honour and glory
      <br />
      To highest Father be observed,
      <br />
      To his sweet Son devoted too,
      <br />
      And songs of praise on every hand.
      <br />
      Eternally they never fade,
      <br />
      May thy fame and honour grow
      <br />
      With glory of the Holy Ghost.
    </em>
  </p>
  <h2 className="track-title">9. Kvöldvísur</h2>
  <p>þjóðlag / þjóðvísa úts. Þóra Marteinsdóttir (1978)</p>
  <p>
    Kvölda tekur, sest er sól,
    <br />
    sígur þoka á dalinn.
    <br />
    Komið er fram á kvíaból,
    <br />
    kýrnar, féð og smalinn.
  </p>
  <p>
    Senn er komið sólarlag,
    <br />
    sést á norðurheiðum,
    <br />
    líður á þennan dýrðardag:
    <br />
    drottinn stýri leiðum.
  </p>
  <p>
    Senn er komið sólarlag,
    <br />
    sést á norðurfjöllum,
    <br />
    líður á þennan dýrðardag:
    <br />
    drottinn hjálpi oss öllum.
  </p>
  <p>
    Senn er komið sólarlag,
    <br />
    sést á norðurtindum,
    <br />
    líður á þennan dýrðardag:
    <br />
    drottinn stýri vindum.
  </p>
  <p>
    Senn er komið sólarlag,
    <br />
    sendi oss drottinn friðinn
    <br />
    og svo gefi annan dag
    <br />
    eftir þennan liðinn.
  </p>
  <p>&nbsp;</p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Night has come, the sun has set (Evening song)</strong>
    </em>
  </p>
  <p>
    <em>
      Night has come, the sun has set,
      <br />
      fog descends on the valley.
      <br />
      On home to Kvíaból have come
      <br />
      the cows, sheep, and herdsman.
    </em>
  </p>
  <p>
    <em>
      Soon the sunset has arrived,
      <br />
      over the northern peaks,
      <br />
      now past is this glorious day;
      <br />
      may the Lord steers the winds.
    </em>
  </p>
  <p>
    <em>
      Soon the sunset has arrived,
      <br />
      over the northern heath,
      <br />
      now past is this glorious day;
      <br />
      may the Lord steer the course.
    </em>
  </p>
  <p>
    <em>
      Soon the sunset has arrived,
      <br />
      over the northern cliffs,
      <br />
      now past is this glorious day;
      <br />
      may the Lord help the sick.
    </em>
  </p>
  <p>
    <em>
      Soon the sunset has arrived,
      <br />
      over the northern fells,
      <br />
      now past is this glorious day;
      <br />
      may the Lord help us all.
    </em>
  </p>
  <p>
    <em>
      Soon the sunset has arrived,
      <br />
      may the Lord send to us peace,
      <br />
      and may he give one more day
      <br />
      just as sweet as this one.
    </em>
  </p>
  <h2 className="track-title">10. Örlög</h2>
  <p>Lag: Þóra Marteinsdóttir (1978) / Ljóð: Ása Hlín Benediktsdóttir (1984)</p>
  <p>
    Þursar, nornir, dísir dauða
    <br />
    spinna nóttu
    <br />
    dreyra rauða.
    <br />
    Hver er að spinna
    <br />
    hvaðan kemur?
    <br />
    Þrjár í einni, ein í þremur.
    <br />
    Hver í spuna rokkinn lemur?
    <br />
    Sitja saman berja stokka
    <br />
    ata blóði
    <br />
    spuna rokka.
    <br />
    Hvín í eyrum feygra manna
    <br />
    hvísla milli bitinna tanna:
    <br />
    Manna lífi
    <br />
    engu unni
    <br />
    Nornir þjá hjá Urðabrunni.
    <br />
    Hver er að spinna
    <br />
    hvaðan kemur?
    <br />
    Hver í spuna stokkinn lemur?
    <br />
    Þrjár í einni, ein í þremur
    <br />
    spinna andans þráð í duld.
    <br />
    Vita örlög öðrum fremur
    <br />
    Urður, Verðandi og Skuld.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    Giants, witches, goddesses of death
    <br />
    spin thread at night, bloody red.
    <br />
    Who is spinning, coming whence?
    <br />
    Three in one, one as three.
    <br />
    Who is beating the spinning wheel?
    <br />
    Sitting together beating the crate,
    <br />
    smearing with blood the spinning wheel.
    <br />
    Screeches in the ears of the doomed
    <br />
    whispering through the teeth:
    <br />
    Lives of man, no one cherishes,
    <br />
    Three witches by the well of Urður.
    <br />
    Who is spinning, coming whence?
    <br />
    Who beats the spinning crate?
    <br />
    Three in one, one as three.
    <br />
    Spinning the spirited thread.
    <br />
    Know fate better than others,
    <br />
    Urður, Verðandi, and Skuld.
  </p>
  <h2 className="track-title">11. Hrímey</h2>
  <p>Lag: Hugi Guðmundsson (1977) / Ljóð: Sigurbjörg Þrastardóttir (1973)</p>
  <p>
    Dynur blóð í djúpu bergi,
    <br />
    fláir hamrar fara hvergi.
    <br />
    Dáin varstu, Draumey,
    <br />
    fagra, gamla Flaumey.
  </p>
  <p>
    Vösk og snör í vaðmálstreyju,
    <br />
    heybrók samt í háskabeygju.
    <br />
    Allt mun bjargast, Ísey.
    <br />
    Heiður, makt og Prísey.
  </p>
  <p>
    Leifturviti, þoka, strönd og þing;
    <br />
    megingjarðir marka ysta hring.
    <br />
    Hjartans þakkir, leðurstrýkta land
    <br />
    sem tryggir oss vort tígulega strand.
  </p>
  <p>
    Flóknir stígar, fléttur, mosi,
    <br />
    víðar grundir, vetrarrosi.
    <br />
    Hertu oddinn, Hrímey.
    <br />
    Reistu makkann, Rímey.
  </p>
  <p>
    Byggðu nú á breiðum mergi,
    <br />
    fláir hamrar fara hvergi.
    <br />
    Gæfa margra, Grátey,
    <br />
    kónglaus alltaf Kátey!
  </p>
  <p>
    Leifturviti, þoka, strönd og þing;
    <br />
    megingjarðir marka ysta hring.
    <br />
    Ástarþakkir úfna undrahaf,
    <br />
    sem þrjóskast við að þrýsta okkur í kaf.
  </p>
  <p>
    Leifturviti, þoka, strönd og þing;
    <br />
    megingjarðir marka ysta hring.
    <br />
    Heilar þakkir hugumstóra grjót,
    <br />
    sem brýtur ekki bjargarlausan fót.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Island of Rime</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Translation by Reynir Axelsson</strong>
    </em>
  </p>
  <p>
    <em>
      Blood thunders in the deep rock,
      <br />
      sloping crags are going nowhere.
      <br />
      You were dead, Island of Dreams,
      <br />
      beautiful, old Island of Streams.
    </em>
  </p>
  <p>
    <em>
      Gallant and swift in a woolen sweater,
      <br />
      but a coward in a dangerous bend.
      <br />
      All will be saved, Island of Ice.
      <br />
      Honour, might and Island of Praise.
    </em>
  </p>
  <p>
    <em>
      Flashing lighthouse, mist, strand and assembly;
      <br />
      power-girdles mark the outermost circle.
      <br />
      Heartful thanks, leather-lashed land
      <br />
      that ensures us our majestic stranding.
    </em>
  </p>
  <p>
    <em>
      Tangled paths, lichen, moss,
      <br />
      wide grounds, stormy winter-rain.
      <br />
      Harden your spear point, Island of Rime.
      <br />
      Raise your mane, Island of Rhyme.
    </em>
  </p>
  <p>
    <em>
      Build now on your broad foundation,
      <br />
      sloping crags are going nowhere.
      <br />
      Good fortune of many, Island of Weeping,
      <br />
      always kingless, Island of Merriment.
    </em>
  </p>
  <p>
    <em>
      Flashing lighthouse, mist, strand and assembly;
      <br />
      power-girdles mark the outermost circle.
      <br />
      Loving thanks, storm-tossed sea of wonders,
      <br />
      that stubbornly refuses to thrust us into the deep.
    </em>
  </p>
  <p>
    <em>
      Flashing lighthouse, mist, strand and assembly;
      <br />
      power-girdles mark the outermost circle.
      <br />
      Sincere thanks, intrepid rocks
      <br />
      that do not break a defenseless foot.
    </em>
  </p>
  <h2 className="track-title">12. Gott ár oss gefi</h2>
  <p>Lag: Bára Grímsdóttir (1960) / Texti: Jón Þorsteinsson (1570–1627)</p>
  <p>
    Gott ár oss gefi enn
    <br />
    Guð allrar náðar.
    <br />
    Signi hann sína menn
    <br />
    og sálir þjáðar.
    <br />
    Gott ár og góðan frið,
    <br />
    Guð minn oss sendi.
    <br />
    Hann sjálfur leggi oss lið
    <br />
    með líknarhendi.
  </p>
  <p>
    Gott ár með innri hægð
    <br />
    og annarri prýði.
    <br />
    Veiti oss viskunægð
    <br />
    vor Jesús fríði.
  </p>
  <p>
    Gott ár að lifa rétt
    <br />
    en láta af illu.
    <br />
    Svo forðumst svik og prett,
    <br />
    syndir og villu.
  </p>
  <p>
    Gott ár að auðgast mest,
    <br />
    í öllum gæðum.
    <br />
    Varfærir verðum best,
    <br />í verki og ræðum.
  </p>
  <p>
    Gott ár á allan hátt,
    <br />
    í öllum stéttum.
    <br />
    Lifum í sóma og sátt
    <br />
    með siðum réttum.
  </p>
  <p>
    Gott ár allt gangi vel,
    <br />
    á sjó og landi.
    <br />
    Guði ég oss bífel
    <br />
    svo enginn grandi.
  </p>
  <p>
    Gott ár oss gefi minn
    <br />
    Guð öllum saman.
    <br />
    Fyrir Krist soninn sinn,
    <br />
    svo er það, amen.
  </p>
  <p>
    <em>
      <strong>English Translation</strong>
    </em>
  </p>
  <p>
    <em>
      <strong>Give us a good year</strong>
    </em>
    <br />
    <em>Translation by Chris Foster</em>
  </p>
  <p>
    <em>
      Give us another good year
      <br />
      God of all grace.
      <br />
      May he bless his people
      <br />
      and suffering souls.
    </em>
  </p>
  <p>
    <em>
      A good year and good peace
      <br />
      send to us my God.
      <br />
      He himself helps us
      <br />
      with his merciful hand.
    </em>
  </p>
  <p>
    <em>
      A good year with inner calm
      <br />
      and other beauty.
      <br />
      Grant us abundant wisdom,
      <br />
      our precious Jesus.
    </em>
  </p>
  <p>
    <em>
      A good year to live right
      <br />
      and to turn from evil,
      <br />
      so as to avoid fraud and trickery,
      <br />
      sin and error.
    </em>
  </p>
  <p>
    <em>
      A good year to be most enriched
      <br />
      with all goodwill.
      <br />
      A cautious approach is best,
      <br />
      in deed and speech.
    </em>
  </p>
  <p>
    <em>
      A good year in every way,
      <br />
      in all walks of life.
      <br />
      We shall live in honour and peace
      <br />
      with good conduct.
    </em>
  </p>
  <p>
    <em>
      A good year in which may all go well
      <br />
      on sea and land.
      <br />
      I commend us to God
      <br />
      so nobody harms us.
    </em>
  </p>
  <p>
    <em>
      Give us all a good year
      <br />
      my God,
      <br />
      for Christ his son.
      <br />
      Thus it is. Amen.
    </em>
  </p>
  <h2 className="track-title">13. Krummavísur</h2>
  <p>
    Íslenskt þjóðlag / Texti: Jón Thoroddsen (1818–1868)
    <br />
    Útsetning: Auður Guðjohnsen (1975)
  </p>
  <p>
    Krummi svaf í klettagjá,
    <br />
    kaldri vetrarnóttu á,
    <br />
    verður margt að meini.
    <br />
    Fyrr en dagur fagur rann
    <br />
    freðið nefið dregur hann
    <br />
    undan stórum steini.
  </p>
  <p>
    „Allt er frosið úti gor,
    <br />
    ekkert fæst við ströndu mor
    <br />
    svengd er metti mína;
    <br />
    ef að húsum heim ég fer
    <br />
    heimafrakkur bannar mér
    <br />
    seppi úr sorpi að tína.“
  </p>
  <p>
    „Öll er þakin ísi jörð,
    <br />
    ekki séð á holtabörð
    <br />
    fleygir fuglar geta;
    <br />
    en þó leiti út um mó,
    <br />
    auða hvergi lítur tó;
    <br />
    hvað á hrafn að eta?“
  </p>
  <p>
    Á sér krummi ýfði stél,
    <br />
    einnig brýndi gogginn vel,
    <br />
    flaug úr fjallagjótum,
    <br />
    lítur yfir byggð og bú,
    <br />
    á bæjum fyrr en vakna hjú,
    <br />
    veifar vængjum skjótum.
  </p>
  <p>
    Sálaður á síðu lá
    <br />
    sauður feitur garði hjá,
    <br />
    fyrrum frár á velli.
    <br />
    „Krunk, krunk! nafnar, komið hér!
    <br />
    krunk, krunk! því oss búin er
    <br />
    krás á köldu svelli.“
  </p>
  <p>&nbsp;</p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      The raven slept in a rock-cleft
      <br />
      on a cold winter’s night;
      <br />
      much wickedness abound.
      <br />
      Before the bright day dawns,
      <br />
      he drags his frozen beak
      <br />
      from under a large rock.
    </em>
  </p>
  <p>
    <em>
      “Everything is frozen outside,
      <br />
      nothing to be found on the beaches,
      <br />
      my hunger I must soothe.
      <br />
      If to any house I go,
      <br />
      the hound forbids me
      <br />
      to pluck my meal from the scraps.”
    </em>
  </p>
  <p>
    <em>
      “The land is icebound,
      <br />
      nothing to be seen on the hilltops,
      <br />
      even by flying birds.
      <br />
      No matter how I search the marsh,
      <br />
      nary a tuft of grass to be seen.
      <br />
      What is a raven to eat?”
    </em>
  </p>
  <p>
    <em>
      The raven ruffles his tail
      <br />
      and sharpens his beak,
      <br />
      flies from a mountain crevice,
      <br />
      looks over house and hamlet,
      <br />
      over the farms before the people wake,
      <br />
      fluttering his wings hurriedly.
    </em>
  </p>
  <p>
    <em>
      Dead on its side lies
      <br />
      a plump sheep in a pasture,
      <br />
      once vigorous in the field.
      <br />
      “Caw, caw! Brothers, come here!
      <br />
      Caw, caw! Since for us is ready
      <br />a feast on this cold ground.”
    </em>
  </p>
  <h2 className="track-title">14. Heyr himna smiður</h2>
  <p>
    Lag: Þorkell Sigurbjörnsson (1938–2013) / Texti: Kolbeinn Tumason
    (1173–1208)
  </p>
  <p>
    Heyr, himna smiður,
    <br />
    hvers skáldið biður,
    <br />
    komi mjúk til mín
    <br />
    miskunnin þín.
    <br />
    Því heit eg á þig,
    <br />
    þú hefur skaptan mig,
    <br />
    ég er þrællinn þinn,
    <br />
    þú ert Drottinn minn.
  </p>
  <p>
    Guð, heit eg á þig,
    <br />
    að græðir mig,
    <br />
    minnst, mildingur, mín,
    <br />
    mest þurfum þín.
    <br />
    Ryð þú, röðla gramur,
    <br />
    ríklyndur og framur,
    <br />
    hölds hverri sorg
    <br />
    úr hjartaborg.
  </p>
  <p>
    Gæt, mildingur, mín,
    <br />
    mest þurfum þín
    <br />
    helzt hverja stund
    <br />
    á hölda grund.
    <br />
    Set, meyjar mögur,
    <br />
    máls efni fögur,
    <br />
    öll er hjálp af þér,
    <br />í hjarta mér.
  </p>
  <p>
    <em>
      <strong>English translation</strong>
    </em>
  </p>
  <p>
    <em>
      Hear, heaven’s maker,
      <br />
      what the poet asks.
      <br />
      May softly come unto me
      <br />
      your mercy.
      <br />
      So I call on you,
      <br />
      for you created me.
      <br />
      I am your servant,*
      <br />
      you are my Lord.
    </em>
  </p>
  <p>
    <em>
      God, I call upon you
      <br />
      to heal me.
      <br />
      Remember me, prince of peace,
      <br />
      and us who most need you.
      <br />
      Drive out, king of the sun,
      <br />
      generous and great,
      <br />
      all human sorrow
      <br />
      from the city of the heart.
    </em>
  </p>
  <p>
    <em>
      Watch over me, prince of peace,
      <br />
      and us who most need you,
      <br />
      through every moment
      <br />
      in this world of men.
      <br />
      Send me, son of the maid,
      <br />
      beautiful thoughts,
      <br />
      all aid is from you,
      <br />
      in my heart.
    </em>
  </p>
  <h2 className="track-title">15. Hér á ég heima</h2>
  <p>
    <strong>Lag, texti og útsetning:</strong> Auður Guðjohnsen (1975)
    <br />
    <em>
      <strong>Melody, lyrics, arrangement: </strong>Auður Guðjohnsen (1975)
    </em>
  </p>
  <p>
    Hjarta mitt er hér á þínum svörtu söndum,
    <br />
    þú kallar mig heim að ögurskornum ströndum
    <br />
    og ég er þér bundinn órjúfanlegum böndum,
    <br />
    mín ástkæra eyja.
    <br />
    Ég tigna þína sköpun og vegsama hvern reit,
    <br />í vetrarsól er fegurst hin íslenska sveit.
  </p>
  <p>
    Þú ert landið mitt, mín heimafagra eyja,
    <br />
    skammdegin þín í sátt ég kýs að þreyja
    <br />
    og ég er þér bundinn órjúfanlegum böndum
    <br />
    mín ástkæra eyja og hér á ég heima.
  </p>
  <p>
    <strong>
      <em>English translation</em>
    </strong>
  </p>
  <p>
    <em>
      My heart is here on your black sands,
      <br />
      You call me home to your jagged shores,
      <br />
      And to you I am bound by unbreakable bonds,
      <br />
      My beloved island.
      <br />
      I worship your creation and celebrate all the land.
      <br />
      In winter’s sun is the Icelandic country the loveliest.
    </em>
  </p>
  <p>
    <em>
      You are my native land, my beautiful island.
      <br />
      In peace I choose to endure your winter darkness,
      <br />
      And to you I am bound by unbreakable bonds,
      <br />
      My beloved island, and here is my home.
    </em>
  </p>
</div>

  );

  export default BarbaraMaer;